const links = [
  {
    label: 'Github',
    url: 'https://github.com/andsonlourenco'
  },
  {
    label: "Youtube",
    urrl: 'https://www.youtube.com.br/andsonlourenco'
  },
  {
    label: "Instagram",
    urrl: 'https://www.Instagram.com.br/andson_lourenco'
  }
]
export default links