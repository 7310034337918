import { Github } from "styled-icons/boxicons-logos/Github"
import { Youtube } from "styled-icons/boxicons-logos/Youtube"
import { Instagram } from "styled-icons/boxicons-logos/Instagram"

const Icons = {
  Github,
  Youtube,
  Instagram
}

export default Icons