import React from "react"
import getThemeColor from "../../utils/getThemeColor"
import links from "./content"

import * as S from "./styled"

const MenuLinks = () => (
  <S.MenuLinksWapper>
    <S.MenuLinksList>
      {links.map((link, i) =>
        <S.MenuLinksItem key={i}>
          <S.MenuLinksLink cover direction="left" bg={getThemeColor()} duration={0.6} to={link.url} activeClassName="active">
            {link.label}
          </S.MenuLinksLink>
        </S.MenuLinksItem>
      )}
    </S.MenuLinksList>
  </S.MenuLinksWapper>
)

export default MenuLinks